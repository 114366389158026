
import { Component, Vue } from 'vue-property-decorator'
import { apiShopsLists, apiAccountAdd, apiAccountDetail, apiAccountEdit } from '@/api/account'
import config from '@/config'
import { debounce } from '@/utils/util'
import MaterialSelect from '@/components/material-select/index.vue'

@Component({
  components: {
    MaterialSelect
  }
})

export default class ListsDetail extends Vue {
  /** S Data **/

  identity: number | null = null; // 当前编辑用户的身份ID

  shopData: any = []

  host: any = config.baseURL
  // 添加商城表单数据
  form: any = {
    name: '', // 名称
    account: '', // 账号
    password: '', // 密码
    password_confirm: '', // 确认密码
    is_kefu: 0, // 设置客服
    shops: '*'
  };

  shops = ['*']

  // 表单校验
  rules = {
    name: [{ required: true, message: '请输入管理员昵称', trigger: 'blur' }],
    account: [
      { required: true, message: '请输入管理员账号', trigger: 'blur' }
    ],
    password: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      {
        validator: (rule: Record<string, any>, value: string, callback: any) => {
          !value ? callback(new Error('请输入密码')) : callback()
        },
        trigger: 'blur'
      }
    ],
    password_confirm: [
      { required: true, message: '请再次输入密码', trigger: 'blur' },
      {
        validator: (rule: Record<string, any>, value: string, callback: any) => {
          if (this.form.password) {
            if (!value) callback(new Error('请再次输入密码'))
            if (value !== this.form.password) { callback(new Error('两次输入密码不一致!')) }
          }
          callback()
        },
        trigger: 'blur'
      }
    ],
    shops: [{ required: true, message: '请输入管理范围', trigger: 'blur' }]
  };

  /** E Data **/

  /** S Methods **/
  // 管理范围发生变化
  onManageRange (e: string[]) : void {
    if (e.includes('*')) {
      const lastEl = e[e.length - 1]
      if (lastEl === '*') {
        this.shops = ['*']
      } else {
        this.shops = e.filter(item => item !== '*')
      }
    } else {
      if (e.length === this.shopData.length) {
        this.shops = ['*']
      }
    }
    this.$set(this.form, 'shops', this.shops.join(','))
    console.log(this.form)
  }

  // 点击表单提交
  onSubmit (formName: string) : void {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement
    refs.validate((valid: boolean): any => {
      if (!valid) return

      if (!this.identity) {
        this.handleShopAdd()
      } else {
        this.handleShopEdit()
      }
    })
  }

  // 获取商城列表
  async getShopLists (): Promise<void> {
    const { lists } = await apiShopsLists({ page_type: 0 });
    this.shopData = lists.map((item: any) => {
      item.id = item.id.toString()
      return item
    })
  }

  // 添加商城
  async handleShopAdd (): Promise<void> {
    await apiAccountAdd({ ...this.form })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 编辑商城
  async handleShopEdit (): Promise<void> {
    await apiAccountEdit({ ...this.form, id: this.identity })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 获取详情
  async getAccountDetailFunc (): Promise<void> {
    const res: any = await apiAccountDetail({
      id: this.identity as number
    })
    Object.keys(res).map((key) => {
      if ((key === 'shops' && res[key])) {
        this.shops = res[key].split(',')
      }
      this.$set(this.form, key, res[key])
    })
  }
  /** E Methods **/

  /** S Life Cycle **/
  created () {
    const query: any = this.$route.query
    this.getShopLists()
    // 编辑模式：初始化数据
    this.identity = +query.id
    if (+query.id) {
      this.getAccountDetailFunc()
    }

    this.onSubmit = debounce(this.onSubmit, 2000)
  }
  /** E Life Cycle **/
}
