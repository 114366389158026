import request from '@/plugins/axios'

// 管理员列表
export const apiAccountLists = (params: any) => request.get('/account/lists', {params})

// 添加管理员
export const apiAccountAdd = (params: any) => request.post('/account/add', params)

// 管理员详情
export const apiAccountDetail = (params: any) => request.get('/account/detail', {params})

// 编辑管理员
export const apiAccountEdit = (params: any) => request.post('/account/edit', params)

// 删除管理员
export const apiAccountDelete = (params: any) => request.post('/account/delete', params)

// 重置密码
export const apiAccountReset = (params: any) => request.post('/account/reset', params)

// 商城列表
export const apiShopsLists = (params: any) => request.get('/shop.shop/lists', { params })
